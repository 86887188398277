import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
  Checkbox
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import { CollectListHead } from '../../components/products';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function CollectModal({
  targetProductCode,
  targetProductName,
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const valids = useRef({});
  const vals = useRef({});
  const [err, setErr] = useState('');
  const [selected, setSelected] = useState([]);

  useEffect(() => {}, []);

  useEffect(() => {
    setList([]);
    if (targetProductCode) {
      _getList(targetProductCode);
    }
  }, [targetProductCode, updateDt]);

  const _getList = async (product_code) => {
    setIsLoading(true);
    const {
      data: { result, data }
    } = await SystemLib.api(
      `product/lots`,
      {
        product_code
      },
      'post'
    );
    setIsLoading(false);
    if (result) {
      if (data) {
        const col = [];
        data.forEach((v) => {
          if (v.status === '期限切れ' || v.status === '回収必須') {
            col.push(v.lot_number);
          }
        });
        setSelected(col);
      }
      setList(data);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.lot_number);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const _setState = async (id, status) => {
  //   const {
  //     data: { result }
  //   } = await SystemLib.api(
  //     `stock/column/status`,
  //     {
  //       id,
  //       status
  //     },
  //     'post'
  //   );
  //   if (result) {
  //     _getList(targetDeviceCode, targetColumnNumber);
  //   }
  // };

  const TABLE_HEAD = [
    { id: 'lot_number', label: 'ロット番号', alignRight: false },
    { id: 'device', label: 'デバイス内在荷', alignRight: false },
    { id: 'storage', label: '保管庫内在荷', alignRight: false },
    { id: 'expiry_dt', label: '消費期限', alignRight: false },
    { id: 'expiry_dts', label: '残日数', alignRight: false },
    { id: 'status', label: '状況', alignRight: false }
  ];

  // const _valid = () => {
  //   let ch = true;
  //   Object.keys(valids.current).forEach((key) => {
  //     if (!valids.current[key]) ch = false;
  //   });
  //   if (ch) {
  //     let sum = 0;
  //     Object.keys(vals.current).forEach((key) => {
  //       sum += Number(vals.current[key]);
  //     });
  //     if (sum > targetMax) {
  //       ch = false;
  //     } else if (sum === 0) {
  //       ch = false;
  //     }
  //   }
  //   setIsValid(ch);
  // };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Draggable
            cancel="input"
            defaultPosition={{ x: 0, y: 0 }}
            positionOffset={{ x: '-50%', y: '-50%' }}
          >
            <Box sx={styles.box} spacing={2} maxHeight="80%">
              <Stack direction="row" justifyContent="center" alignItems="center" width="100%">
                <Typography fontSize={18} align="center" color="#2e8b57" sx={{ marginRight: 2 }}>
                  {targetProductCode}：{targetProductName}
                </Typography>
              </Stack>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <CollectListHead
                      headLabel={TABLE_HEAD}
                      rowCount={list.length}
                      numSelected={selected.length}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {list.map((row, index) => (
                        <ItemRow
                          key={index}
                          row={row}
                          onChange={(v) => {}}
                          onValid={(v) => {}}
                          handleClick={handleClick}
                          selected={selected}
                        />
                      ))}
                      {isLoading === false && list.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                対象在荷がありません
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                読み込み中
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Box textAlign="center" sx={{ marginTop: 3 }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={selected.length === 0}
                  onClick={() => {
                    setConfOk(() => async () => {
                      setIsLoading(true);
                      const {
                        data: { result, data }
                      } = await SystemLib.api(
                        `product/collect`,
                        {
                          product_code: targetProductCode,
                          lot_numbers: selected
                        },
                        'post'
                      );
                      setIsLoading(false);
                      if (result) {
                        reload(targetProductCode);
                        onClose();
                      } else {
                        alert('登録に失敗しました');
                      }
                    });
                    setConf(true);
                  }}
                >
                  回収指示
                </LoadingButton>
              </Box>
            </Box>
          </Draggable>
        </LocalizationProvider>
      </Modal>
      <ConfDialog
        open={conf}
        onOk={() => {
          confOk();
          setConf(false);
        }}
        onCancel={() => {
          setConf(false);
        }}
        onClose={() => {
          setConf(false);
        }}
      >
        実行してもよろしいでしょうか？
      </ConfDialog>
    </>
  );
}

function ItemRow({ row, onChange, onValid, handleClick, selected }) {
  const { lot_number, device, storage, expiry_dt, expiry_days, status } = row;
  let exp_color = null;
  switch (status) {
    case '期限切れ':
      exp_color = '#fad9d2';
      break;
    case '回収必須':
      exp_color = '#fad9d2';
      break;
    case '回収注意':
      exp_color = '#fafad2';
      break;
    default:
      break;
  }

  useEffect(() => {}, []);

  const isItemSelected = selected.indexOf(lot_number) !== -1;
  return (
    <TableRow hover key={lot_number} tabIndex={-1}>
      <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, lot_number)} />
      </TableCell>
      <TableCell scope="row>">{lot_number}</TableCell>
      <TableCell scope="row>">{device}</TableCell>
      <TableCell scope="row">{storage}</TableCell>
      <TableCell scope="row">{expiry_dt}</TableCell>
      <TableCell scope="row">{expiry_days}</TableCell>
      <TableCell
        scope="row"
        width={50}
        align="center"
        sx={{ backgroundColor: exp_color, whiteSpace: 'nowrap' }}
      >
        {status}
      </TableCell>
    </TableRow>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
