import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function ShippingModal({ targetId = 0, open, onClose, updateDt, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {}, [targetId, updateDt]);

  // const getItem = async (id) => {
  //   const {
  //     data: { result, data }
  //   } = await SystemLib.api(
  //     `ship/item`,
  //     {
  //       id
  //     },
  //     'post'
  //   );
  //   if (result) {
  //     formik.setValues({
  //       quantity: data.quantity
  //     });
  //   }
  // };

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(0, '0以上の数字を入れてください')
    // .max(max, `${max}以下の数字を入力してください`)
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    making_dt: Yup.date().required('製造日は必須です'),
    ship_dt: Yup.date().required('発送日は必須です'),
    stock_expected_dt: Yup.date().required('到着予定日は必須です')
  });

  const formik = useFormik({
    initialValues: {
      making_dt: moment().toDate(),
      ship_dt: moment().toDate(),
      stock_expected_dt: moment().add(1, 'd').toDate()
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      if (
        moment(formik.values.ship_dt).format('YYYY-MM-DD') >=
          moment(formik.values.making_dt).format('YYYY-MM-DD') &&
        moment(formik.values.stock_expected_dt).format('YYYY-MM-DD') >=
          moment(formik.values.ship_dt).format('YYYY-MM-DD')
      ) {
        const { data } = await SystemLib.api(
          'order/shipping',
          {
            order_id: targetId,
            making_dt: moment(formik.values.making_dt).format('YYYY-MM-DD'),
            ship_dt: moment(formik.values.ship_dt).format('YYYY-MM-DD'),
            stock_expected_dt: moment(formik.values.stock_expected_dt).format('YYYY-MM-DD')
          },
          'post'
        );
        if (data.result) {
          reload(targetId);
          onClose();
        } else {
          alert('登録に失敗しました');
        }
      } else {
        alert('製造日／発送日／到着予定日の日付の順番をご確認ください');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Draggable
              cancel="input"
              defaultPosition={{ x: 0, y: 0 }}
              positionOffset={{ x: '-50%', y: '-50%' }}
            >
              <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
                <Stack direction="column" spacing={2} mt={0} mr={2} width="100%" mb={2}>
                  <DesktopDatePicker
                    id="making_dt"
                    label="製造日"
                    inputFormat="yyyy-MM-dd"
                    {...getFieldProps('making_dt')}
                    value={formik.values.making_dt}
                    onChange={(v) => {
                      formik.setFieldValue('making_dt', moment(v).toDate());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={errors.making_dt ? '製造日をご確認ください' : null}
                      />
                    )}
                  />
                  <DesktopDatePicker
                    id="ship_dt"
                    label="発送日"
                    inputFormat="yyyy-MM-dd"
                    {...getFieldProps('ship_dt')}
                    value={formik.values.ship_dt}
                    onChange={(v) => {
                      formik.setFieldValue('ship_dt', moment(v).toDate());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={errors.ship_dt ? '発送日をご確認ください' : null}
                      />
                    )}
                  />
                  <DesktopDatePicker
                    id="stock_expected_dt"
                    label="到着予定日"
                    inputFormat="yyyy-MM-dd"
                    {...getFieldProps('stock_expected_dt')}
                    value={formik.values.stock_expected_dt}
                    onChange={(v) => {
                      formik.setFieldValue('stock_expected_dt', moment(v).toDate());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={errors.stock_expected_dt ? '到着予定日をご確認ください' : null}
                      />
                    )}
                  />
                </Stack>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  発送する
                </LoadingButton>
              </Box>
            </Draggable>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
