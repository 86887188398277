import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Box, Grid, Container, Typography, Button, Badge } from '@mui/material';

import { Icon } from '@iconify/react';

import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import npmFill from '@iconify/icons-eva/npm-fill';
import checkmark from '@iconify/icons-eva/checkmark-square-outline';
import listFill from '@iconify/icons-eva/list-fill';
import cubeFill from '@iconify/icons-eva/cube-fill';
import cartFill from '@iconify/icons-eva/shopping-cart-fill';
import bagFill from '@iconify/icons-eva/shopping-bag-fill';
import carFill from '@iconify/icons-eva/car-fill';
import trashFill from '@iconify/icons-eva/trash-2-fill';
import awardFill from '@iconify/icons-eva/award-fill';
import keypadFill from '@iconify/icons-eva/keypad-fill';
import harddriveFill from '@iconify/icons-eva/hard-drive-fill';
import logoutFill from '@iconify/icons-eva/log-out-fill';
import folderOutline from '@iconify/icons-eva/folder-outline';
import pieChartOutline from '@iconify/icons-eva/pie-chart-outline';

import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------

import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';

// components
import Page from '../components/Page';
import sidebarConfig from '../layouts/dashboard/SidebarConfig';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const { userInfo } = SystemLib;
  const menus = sidebarConfig[userInfo.role];
  const [badges, setBadges] = useState({});

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    const tmp = { ...badges };
    const keys = Object.keys(menus);
    for (let i = 0; i < keys.length; i += 1) {
      if (menus[keys[i]].badge) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await SystemLib.api(`${menus[keys[i]].badge}/badge`, {}, 'post');
        if (data.result) {
          tmp[menus[keys[i]].badge] = data.data.badge;
        } else {
          tmp[menus[keys[i]].badge] = 0;
        }
      }
    }
    setBadges(tmp);
  };

  return (
    <Page title="ホーム">
      <Container maxWidth="xl">
        {/* <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box> */}
        <Grid container spacing={2}>
          {menus.map((menu) => {
            if (menu.title === 'ホーム' || menu.title === 'hr') return null;
            return (
              <Grid key={menu.title} item xs={12} sm={6} md={4}>
                <Button
                  variant="outlined"
                  startIcon={menu.icon}
                  sx={{ width: '100%', justifyContent: 'flex-start', padding: 3 }}
                  onClick={() => {
                    navigate(menu.path);
                  }}
                >
                  <Badge
                    badgeContent={badges[menu.badge] ? badges[menu.badge] : 0}
                    color="error"
                    sx={{
                      '& .MuiBadge-badge': {
                        right: -20,
                        top: 13,
                        padding: '0 4px'
                      }
                    }}
                  >
                    {menu.title}
                  </Badge>
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Page>
  );
}
