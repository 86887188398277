import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import { CollectListHead } from '../../components/stock';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function StorageModal({
  targetId,
  targetProduct,
  open,
  onClose,
  updateDt,
  reload,
  targetMax
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const valids = useRef({});
  const vals = useRef({});
  const [err, setErr] = useState('');

  useEffect(() => {}, []);

  useEffect(() => {
    setList([]);
    if (targetProduct) {
      _getList(targetProduct.code);
    }
    valids.current = {};
    vals.current = {};
  }, [targetProduct, updateDt]);

  const _getList = async (product_code) => {
    setIsLoading(true);
    const {
      data: { result, data }
    } = await SystemLib.api(
      `layin/storages`,
      {
        product_code
      },
      'post'
    );
    setIsLoading(false);
    if (result) {
      setList(data);
    }
  };

  const TABLE_HEAD = [
    { id: 'code', label: '倉庫コード', alignRight: false },
    { id: 'name', label: '倉庫名', alignRight: false },
    { id: 'amount', label: '最大保管数', alignRight: false },
    { id: 'sum_all', label: '現保管数', alignRight: false },
    { id: 'sum_product', label: ['当該商品', <br />, '保管数'], alignRight: false },
    { id: 'diff', label: '空き', alignRight: false },
    { id: 'num', label: '保管数', alignRight: true }
  ];

  const _valid = () => {
    let ch = true;
    Object.keys(valids.current).forEach((key) => {
      if (!valids.current[key]) ch = false;
    });
    if (ch) {
      let sum = 0;
      Object.keys(vals.current).forEach((key) => {
        sum += Number(vals.current[key]);
      });
      if (sum !== targetMax) {
        ch = false;
      }
    }
    setIsValid(ch);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Draggable
            cancel="input"
            defaultPosition={{ x: 0, y: 0 }}
            positionOffset={{ x: '-50%', y: '-50%' }}
          >
            <Box sx={styles.box} spacing={2} maxHeight="80%">
              <Stack direction="row" justifyContent="center" alignItems="center" width="100%">
                <Typography fontSize={16} align="center" color="#2e8b57" sx={{ marginRight: 2 }}>
                  {targetProduct.name}
                </Typography>
                <Typography fontSize={16} align="center" color="#2e8b57">
                  保管数：{targetMax}
                </Typography>
              </Stack>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <CollectListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {list.map((row, index) => (
                        <ItemRow
                          key={index}
                          row={row}
                          onChange={(v) => {
                            vals.current[row.code] = v;
                            _valid();
                          }}
                          onValid={(v) => {
                            valids.current[row.code] = v;
                            _valid();
                          }}
                        />
                      ))}
                      {isLoading === false && list.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                保管庫データがありません
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                読み込み中
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Box textAlign="center" sx={{ marginTop: 3 }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                  onClick={() => {
                    console.log(vals.current);
                    setConfOk(() => async () => {
                      setIsLoading(true);
                      const {
                        data: { result, data }
                      } = await SystemLib.api(
                        `layin/stock`,
                        {
                          order_id: targetId,
                          stocks: vals.current
                        },
                        'post'
                      );
                      setIsLoading(false);
                      if (result) {
                        reload(targetId);
                        onClose();
                      } else {
                        alert('登録に失敗しました');
                      }
                    });
                    setConf(true);
                  }}
                >
                  保管する
                </LoadingButton>
              </Box>
            </Box>
          </Draggable>
        </LocalizationProvider>
      </Modal>
      <ConfDialog
        open={conf}
        onOk={() => {
          confOk();
          setConf(false);
        }}
        onCancel={() => {
          setConf(false);
        }}
        onClose={() => {
          setConf(false);
        }}
      >
        実行してもよろしいでしょうか？
      </ConfDialog>
    </>
  );
}

function ItemRow({ row, onChange, onValid }) {
  const { id, code, name, amount, sum_product, sum_all } = row;
  const yupNum = Yup.number()
    .typeError('数字のみ')
    .integer('整数のみ')
    .min(0, '0以上')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    num: yupNum.required('必須')
  });

  const formik = useFormik({
    initialValues: {
      num: 0
    },
    validationSchema: FormSchema,
    onSubmit: async () => {}
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  useEffect(() => {
    onChange(0);
  }, []);

  useEffect(() => {
    onValid(isValid);
  }, [isValid]);

  return (
    <TableRow hover key={id} tabIndex={-1}>
      <TableCell scope="row>">{code}</TableCell>
      <TableCell scope="row>">{name}</TableCell>
      <TableCell scope="row>">{amount}</TableCell>
      <TableCell scope="row>">{sum_all}</TableCell>
      <TableCell
        scope="row>"
        width={120}
        sx={{ backgroundColor: sum_product > 0 ? '#fff8dc' : null }}
      >
        {sum_product}
      </TableCell>
      <TableCell scope="row>">{amount - sum_all}</TableCell>
      <TableCell scope="row" padding="none" align="center" width={90}>
        <TextField
          variant="outlined"
          size="small"
          sx={{ marginLeft: 1 }}
          {...getFieldProps('num')}
          error={Boolean(touched.num && errors.num)}
          helperText={touched.num && errors.num}
          onChange={(v) => {
            onChange(v.target.value);
            formik.handleChange(v);
          }}
          value={formik.values.num}
        />
      </TableCell>
    </TableRow>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
