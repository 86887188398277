import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import backFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/storehouse/products';
import ConfDialog from '../../components/ConfDialog';
import StorageModal from './StorageModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product_code', label: '商品コード', alignRight: false },
  { id: 'lot_number', label: 'ロット番号', alignRight: false },
  { id: 'product_name', label: '商品名', alignRight: false },
  { id: 'quantity', label: '在荷量', alignRight: false },
  { id: 'expiry_dt', label: '消費期限', alignRight: false },
  { id: 'expiry_days', label: '残日数', alignRight: false },
  { id: 'exp_status', label: '状態', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, category1, category2, category3, status) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.product_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _target.product_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _target.lot_number.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  if (status !== '-1') {
    array = filter(array, (_target) => _target.exp_status === status);
  }

  if (category1 !== '-1') {
    array = filter(array, (_target) => _target.category_code1 === category1);
  }
  if (category2 !== '-1') {
    array = filter(array, (_target) => _target.category_code2 === category2);
  }
  if (category3 !== '-1') {
    array = filter(array, (_target) => _target.category_code3 === category3);
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function StorehouseProduct({
  open = false,
  targetCode = '',
  targetName = '',
  onClose,
  updateProductDt
}) {
  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [modal, setModal] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTargetId, setCurrentTargetId] = useState(0);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confDelAll, setConfDelAll] = useState(false);

  const [category1, setCategory1] = useState('-1');
  const [category2, setCategory2] = useState('-1');
  const [category3, setCategory3] = useState('-1');

  const [status, setStatus] = useState('-1');

  const [currentProductCode, setCurrentProductCode] = useState('');
  const [currentProductName, setCurrentProductName] = useState('');

  const [modalStorage, setModalStorage] = useState(false);

  const [currentId, setCurrentId] = useState(0);
  const [currentMax, setCurrentMax] = useState(0);

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [targetCode, updateProductDt]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const filteredItems = applySortFilter(
    items,
    getComparator(order, orderBy),
    filterName,
    category1,
    category2,
    category3,
    status
  );

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getItems = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api(
      'storage/details',
      {
        code: targetCode
      },
      'post'
    );
    if (data.result) {
      setItems(data.data);
    }
    setIsLoading(false);
  };

  const reload = async (id = null) => {
    getItems();
  };

  const classes = useStyles();

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        background: '#fff',
        height: '100%',
        width: '100%',
        display: open ? 'block' : 'none'
      }}
    >
      <Page title="保管庫の現状：商品一覧">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom display="block">
              {targetName}：商品一覧
            </Typography>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Button
                variant="outlined"
                startIcon={<Icon icon={backFill} />}
                onClick={() => {
                  onClose();
                }}
              >
                戻る
              </Button>
            </Stack>
          </Stack>

          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {
                setConfDelAll(true);
              }}
              onChange={(vals) => {}}
              onChangeStatus={(status) => {
                setStatus(status);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          storage_code,
                          product_code,
                          product_name,
                          lot_number,
                          expiry_dt,
                          expiry_days,
                          quantity,
                          exp_status,
                          status
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;

                        let exp_color = null;
                        let exp_label = '適正';
                        switch (exp_status) {
                          case 'expired':
                            exp_color = '#fad9d2';
                            exp_label = '期限切れ';
                            break;
                          case 'warning':
                            exp_color = '#fad9d2';
                            exp_label = '回収必須';
                            break;
                          case 'caution':
                            exp_color = '#fafad2';
                            exp_label = '回収注意';
                            break;
                          default:
                            break;
                        }

                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            <TableCell scope="row>">{product_code}</TableCell>
                            <TableCell scope="row">{lot_number}</TableCell>
                            <TableCell scope="row">{product_name}</TableCell>
                            <TableCell scope="row">
                              <Typography color="#00f" fontSize="0.875rem">
                                {quantity}
                              </Typography>
                            </TableCell>
                            <TableCell scope="row">{expiry_dt}</TableCell>
                            <TableCell scope="row">{expiry_days}</TableCell>
                            <TableCell
                              scope="row"
                              padding="normal"
                              sx={{
                                backgroundColor: exp_color
                              }}
                            >
                              {exp_label}
                            </TableCell>
                            <TableCell scope="row" align="right" width={150} padding="none">
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                              >
                                {quantity > 0 && (
                                  <>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="success"
                                      onClick={() => {
                                        setUpdateDt(moment().unix());
                                        setCurrentId(id);
                                        // setCurrentProduct(product_code);
                                        setCurrentProductCode(product_code);
                                        setCurrentProductName(product_name);
                                        setCurrentMax(quantity);
                                        setModalStorage(true);
                                      }}
                                      disabled={selected.length > 0}
                                    >
                                      保管庫移動
                                    </Button>
                                  </>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        {modalStorage && (
          <StorageModal
            targetCode={targetCode}
            targetId={currentId}
            targetProductCode={currentProductCode}
            targetProductName={currentProductName}
            targetMax={currentMax}
            open={modalStorage}
            onClose={() => {
              setModalStorage(false);
            }}
            updateDt={updateDt}
            reload={(id) => {
              reload(id);
            }}
          />
        )}
      </Page>
    </div>
  );
}
